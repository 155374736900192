<template>
    <div class="content-consult-pqrs">
        <div class="header-page">
            <div class="container-breadcrumb">
                <router-link class="text-breadcrumb" :to="{name: 'Home'}">Inicio</router-link>
                <span class="text-breadcrumb">Consultar PQRS por cuotas</span>
            </div>
            <h1 class="text-center mb-4">¡Consulta el estado de tu PQRS!</h1>
        </div>
        <div class="container">
            <form @submit.prevent="evalFormPQR" class="form-send-pqr">
                <div class="col-12 col-md-4 col-lg pe-md-2 position-relative">
                    <input
                        class="input-quac w-100 mb-4"
                        type="text"
                        @blur="validateBlur('ticket')"
                        @focus="errorNTicket = false"
                        placeholder="Número de ticket"
                        v-model="nTicket"
                    >
                    <span v-if="errorNTicket" class="error-inputs">Este campo es obligatorio</span>
                    <span class="label-input" v-if="nTicket">Número de ticket</span>
                </div>
                <div class="col-12 col-md-4 col-lg px-md-2 position-relative">
                    <input
                        class="input-quac w-100 mb-4"
                        type="text"
                        @blur="validateBlur('doc')"
                        @focus="errorNCC = false"
                        placeholder="Número de documento"
                        v-model="nCC"
                    >
                    <span v-if="errorNCC" class="error-inputs">Este campo es obligatorio</span>
                    <span class="label-input" v-if="nCC">Número de documento</span>
                </div>
                <div class="col-12 col-md-4 col-lg-3 ps-md-2 position-relative d-flex mb-auto">
                    <button
                        class="buton w-100 buton-primary ms-auto px-4"
                        :disabled="loading"
                        type="submit"
                    >
                        <img v-if="!loading" src="@/assets/icons/icon-search.svg" class="me-2" width="19">
                        {{ loading ? 'Cargando...' : 'Consultar' }}
                    </button>
                </div>
            </form>
            <div class="container-summary-pqrs" v-if="infoSolicitudPQRS">
                <h4 class="title-summary-pqrs">
                    Detalle de PQRS
                </h4>
                <hr>
                <div class="card-summary">
                    <div class="card-header-summary">
                        <p class="mb-md-0">Nro de documento: <strong>{{ infoSolicitudPQRS.numero_documento }}</strong></p>
                        <p class="mb-md-0">Nro de ticket: <strong>{{ infoSolicitudPQRS.n_ticket }}</strong></p>
                        <div class="mb-md-0 d-flex flex-wrap align-items-center">
                            Estado:
                            <p class="ms-md-2 badge-estado m-0 col-12 col-md">
                                <strong>{{ infoSolicitudPQRS.estado }}</strong>
                                <span class="text-link" @click="showModalStatus = true">Más información</span>
                            </p>
                        </div>
                    </div>
                    <div class="card-body-summary">
                        <div class="col-12 col-lg-5">
                            <p><strong>Datos solicitante</strong></p>
                            <div class="container-info-solicitante">
                                <div class="container-item-info">
                                    <p class="col-6 pe-2">
                                        Nombre <br>
                                        <span>{{ infoSolicitudPQRS.nombres }}</span>
                                    </p>
                                    <p class="col-6 ps-2">
                                        Apellidos <br>
                                        <span>{{ infoSolicitudPQRS.apellidos }}</span>
                                    </p>
                                </div>
                                <div class="container-item-info">
                                    <p class="col-6 pe-2">
                                        Celular <br>
                                        <span>{{ infoSolicitudPQRS.celular }}</span>
                                    </p>
                                    <p class="col-6 ps-2">
                                        Correo <br>
                                        <span>{{ infoSolicitudPQRS.correo_electronico }}</span>
                                    </p>
                                </div>
                                <div class="container-item-info">
                                    <p class="col-6 pe-2">
                                        Dirección <br>
                                        <span>{{ infoSolicitudPQRS.direccion }}</span>
                                    </p>
                                    <p class="col-6 ps-2">
                                        Ciudad <br>
                                        <span>{{ infoSolicitudPQRS.ciudad }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7 ps-lg-4">
                            <p><strong>Información del PQR</strong></p>
                            <div class="d-flex">
                                <p style="min-width: 150px">Fecha de registro:</p>
                                <p>{{ infoSolicitudPQRS.fecha_creacion.split(' ')[0] }}</p>
                            </div>
                            <div class="d-flex">
                                <p style="min-width: 150px">Tipo:</p>
                                <p>{{ infoSolicitudPQRS.motivo }}</p>
                            </div>
                            <div class="d-flex">
                                <p style="min-width: 150px">Dependencia:</p>
                                <p>{{ infoSolicitudPQRS.dependencia }}</p>
                            </div>
                            <div class="d-flex flex-wrap">
                                <p style="min-width: 150px">Detalle del caso:</p>
                                <p>{{ infoSolicitudPQRS.mensaje }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="modalInfoEstadoPQRS" v-model="showModalStatus" centered hide-footer title="Detalle estado">
            <p v-if="infoSolicitudPQRS">{{ infoSolicitudPQRS.estado_descripcion }}</p>
            <div class="d-flex justify-content-end">
                <button class="buton buton-primary ms-auto p-2" @click="showModalStatus = false">
                    Aceptar
                </button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
    data() {
        return {
            nCC: '',
            errorNCC: false,
            nTicket: '',
            errorNTicket: false,
            loading: false,
            showModalStatus: false,
        }
    },
    computed: {
        ...mapState('pqrQuac', ['infoSolicitudPQRS']),
    },
    methods: {
        ...mapActions('pqrQuac', ['getInfoPRQS']),
        async evalFormPQR() {
            this.validateBlur('ticket')
            this.validateBlur('doc')
            if (!this.errorNTicket && !this.errorNCC) {
                this.loading = true
                await this.getInfoPRQS({ 'nTicket': this.nTicket, 'nCC': this.nCC })
                this.loading = false
            }
        },
        validateBlur(blur) {
            switch (blur) {
                case 'ticket':
                    this.errorNTicket = !(this.nTicket.length > 1)
                    break;
                case 'doc':
                    this.errorNCC = !(this.nCC.length >= 5)
                    break;
                default:
                    break;
            }
        }
    }
}
</script>
<style lang="scss">
.text-link {
    color: var(--primaryColorDark);
    text-decoration: underline;
    cursor: pointer;
}
.content-consult-pqrs {
    position: relative;
    min-height: 80dvh;
    overflow: hidden;
    &::before {
        content: url('../assets/decorador-politicas-1.svg');
        position: absolute;
    }
    &::after {
        content: url('../assets/decorador-politicas-2.svg');
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(50%);
    }
    .badge-estado {
        padding: .3rem .7rem;
        font-size: 1rem;
        background-color: white;
        border-radius: 15px;
        border: 1px solid #A7B5CB;
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }
    .container {
        width: 80%;
        max-width: 1400px;
        margin: 36px auto;
        position: relative;
        z-index: 1;
        @media (max-width: 768px) {
            width: 90%;
        }
    }
    .form-send-pqr {
        margin-bottom: 2rem;
        hr {
            margin-right: -1rem;
            margin-left: -1rem;
        }
    }
    .card-summary {
        background-color: #ffffff80;
        border-radius: 10px;
        overflow: hidden;
        .card-header-summary {
            border-radius: 10px;
            padding: .8rem 1rem;
            background-color: #F3F3F8;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 30px;
            margin-bottom: 1rem;
            @media (width <= 768px) {
                display: block;
            }
        }
        .card-body-summary {
            display: flex;
            flex-wrap: wrap;
            .container-info-solicitante {
                border: 1px solid #A7B5CB;
                border-radius: 10px;
                margin-bottom: 2rem;
                .container-item-info {
                    display: flex;
                    justify-content: space-around;
                    border-bottom: 1px solid #A7B5CB;
                    padding: 1rem 1.5rem;
                    &:last-child {
                        border-bottom: none;
                    }
                    p {
                        margin-bottom: 0;
                        word-break: break-all;
                        span {
                            color: #888888;
                        }
                    }
                }
            }
        }
    }
    .container-summary-pqrs {
        overflow: auto;
        padding: 0;
        margin-bottom: 5rem;
        .title-summary-pqrs {
            font-weight: bold;
            color: var(--primaryColorDark);
        }
        hr {
            border: 2px solid var(--primaryColorDark);
            opacity: 1;
        }
    }
}
</style>